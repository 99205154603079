import { css } from '@emotion/react'
import ProIcon from 'assets/icons/pricing/pro.svg'

const proStyles = ({ theme: { space } }) =>
  css`
    width: max-content;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: calc(100% + ${space[3]});
      top: 50%;
      transform: translate(0, -50%);
      width: ${space[7]};
      height: ${space[4]};
      background-image: url(${ProIcon});
      background-repeat: no-repeat;
      background-position: right center;
      background-size: contain;
    }
  `

export default proStyles
