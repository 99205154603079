import React, { Children } from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import { CardGrid as BaseCardGrid } from './card-grid'
import { CardCarousel as BaseCardCarousel } from './card-carousel'

const Container = styled.div``

const CardGrid = styled(BaseCardGrid)`
  display: none;

  ${mediaQueries.md} {
    display: grid;
  }
`

const CardCarousel = styled(BaseCardCarousel)`
  ${mediaQueries.md} {
    display: none;
  }
`

export const CardSet = ({ storageKey, children, className }) => (
  <Container className={className}>
    <CardGrid>{children}</CardGrid>
    <CardCarousel storageKey={storageKey}>
      {Children.toArray(children).slice(0, 9)}
    </CardCarousel>
  </Container>
)
